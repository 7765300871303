import * as React from 'react'
import { graphql } from 'gatsby'
import { PostHeader } from '../components/section'
import {
  transform as toGlobals,
  ContentfulSettingsDesignerFund,
} from '../data/globals'

interface Props {
  data: {
    settings: {
      edges: { node: ContentfulSettingsDesignerFund }[]
    }
  }
}

export default function TermsOfUse(props: Props) {
  const {
    settings: { edges: globals },
  } = props.data
  const [settings] = globals.map(({ node }) => toGlobals(node))
  return (
    <article>
      <PostHeader title="Terms of Use" />
      <div className="container container--grid" style={{ paddingBottom: 80 }}>
        <div className="container__row">
          <div
            className="wysiwyg child-my-0"
            dangerouslySetInnerHTML={{ __html: settings.termsOfUse }}
          />
        </div>
      </div>
    </article>
  )
}

export const query = graphql`
  query TermsOfUsePage {
    settings: allContentfulSettingsDesignerFund(limit: 1) {
      edges {
        node {
          ...GlobalsFields
        }
      }
    }
  }
`
